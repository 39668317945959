@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    width: 100%;
    margin-inline: auto;
    overflow: auto !important;
}

.auth-slider {
    opacity: 0.8;
    background: linear-gradient(45deg, #4c50ea 0%, #5b5ed5 100%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.hide-scroll::-webkit-scrollbar {
    display: none;
}
.hide-scroll {
    scrollbar-width: 0px;
    -ms-overflow-style: none;
}

.multiselect-button > span {
    flex: initial;
}

.date-picker {
    min-width: 400px;
    height: 2.5rem;
    border: 1px solid #e5e7eb;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.375rem;
    padding-inline: 1rem !important;
    position: relative;
}
.date-picker-error {
    border: 2px solid #e53e3e;
}
.calendar {
    position: absolute;
    right: 1rem;
    top: 5px;
    z-index: 99;
}

.react-calendar button {
    border-radius: 100%;
    /* setting border to white gives a margin effect between dates */
    border: 4px solid white !important;
    height: 48px;
    width: 48px;
}
.react-calendar__tile:enabled:hover {
    background-color: #291A72 !important;
    color: white !important;
}

.react-calendar__tile {
    color: grey !important;
}

.react-calendar__year-view__months__month {
    border-radius: 0 !important;
}
.react-calendar__month-view__days__day--weekend {
    color: grey !important;
}
.react-calendar {
    border: 0 !important;
}
.react-calendar__year-view .react-calendar__tile, 
.react-calendar__century-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile  {
    padding:0 !important;
    border-radius: 0 !important;
}
.react-calendar__tile--hasActive:enabled:hover, 
.react-calendar__tile--hasActive:enabled:focus {
    background: #4c50ea !important;
    color: #fff !important;
}

.react-calendar__navigation button:disabled {
    background: #fff !important;
}
.react-calendar__tile--hasActive {
    background: #4c50ea !important;
    color: #fff !important;
}
.custom-highlight {
    background-color: #EEF3FF !important;
    color: #4c50ea !important;
    font-weight: 600 !important;
}

.react-calendar__tile--active {
    background: #4c50ea !important;
    color: #fff !important;
}
.active-day {
    background-color: #4c50ea !important;
}
.react-calendar__navigation__label:focus, .react-calendar__navigation__label:hover   {
    background-color: white !important;
}
.react-calendar__navigation button:hover {
    background-color: white !important;
}
.react-calendar__navigation__next-button:enabled:focus {
    background-color: #EEF3FF !important;
}

.react-calendar__navigation__prev-button:enabled:focus {
    background-color: #EEF3FF !important;
}
.react-calendar__navigation__prev2-button {
    visibility: hidden !important;
}
.react-calendar__navigation__next2-button {
    visibility: hidden !important;
}
.react-calendar__navigation__prev-button {
    font-size: xx-large !important;
}
.react-calendar__navigation__next-button {
    font-size: xx-large !important; 
}
.react-calendar__navigation button {
    min-width: 48px !important;
    height: 47px;
}
.react-calendar__tile:disabled {
    background-color: unset !important;
    color: #b0afaf !important;
}
abbr:where([title]) {
    text-decoration: none !important;
}


.text-ellipses {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-date-picker-style {
    width: 100%;
    padding: 5px;
    padding-left: 10px;
    border-radius: 6px;
}

.complete-step {
    background-color: #22C55E !important;
}

.in-progress-step {
    background:  #4C50EA;
    border-radius: 1000px;
    color: white;
    width: 22px;
    height: 22px;
    text-align: center;
}

.pending-step {
    background-color: #C6D6FF;
    border: none;
    color: white;
}

/* react-next-calendar */
.rbc-month-view {
    display: block !important;
    background-color: white !important;
}
.rbc-month-row {
  height: 110px !important ;
  min-height: 90px !important ;
  max-height: 180px !important;
}
.rbc-row-segment {
  font-size: 13px !important;
}
.rbc-month-header {
  background-color: #EEF3FF;
}
.rbc-header {
  font-weight: 500 !important;
  padding:6px 3px !important;
}
.rbc-date-cell{
  font-size: 13px !important;
}
.rbc-toolbar button {
    border: none !important;
    border-bottom: 1px solid #e5e7eb !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
.rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
    background-color: #EEF3FF !important;
    border-color: #EEF3FF !important;
}
.rbc-active {
    color: #4c50ea !important;
}
.rbc-toolbar-label {
    color: #291a72 !important;
}

.rbc-toolbar .rbc-btn-group:first-child button {
    border-bottom: none !important;
}
.rbc-toolbar .rbc-btn-group:first-child button:first-child {
    color: #4C50EA !important;
}
.rbc-event {
    margin-bottom: 1px !important;
    padding: 3px 3px !important;
    background-color: #4C50EA !important;
}
